<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ramStepCd"
            label="진행단계"
            v-model="searchParam.ramStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="CHARM 목록"
      tableId="charm"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add_circle" @btnClicked="addCharm" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'charm-list',
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentDates: [],
        ramAssessTypeCd: null,
        stepCd: null,
        ramTechniqueCd: 'RT00000030',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ramStepName',
            field: 'ramStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            label: '평가구분',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '작성자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '승인자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.assessPlan.list.url
      // code setting
      this.$comm.getStepItems('RAM_CHARM_STEP_CD').then(_result => {
        _result.splice(1, 1);
        this.stepItems = _result;
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addCharm() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = 'CHARM 상세'; // CHARM 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row ? row.ramRiskAssessmentPlanId : '',
        ramStepCd: row ? row.ramStepCd : 'RRS0000001',
      };
      this.popupOptions.target = () => import(`${'./charmDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
